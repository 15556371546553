import React from "react"

export default function HelpIcon() {
  return (
    <svg
      width="33"
      height="28"
      viewBox="0 0 39 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="37"
        height="27.6391"
        rx="3.5"
        fill="#4D4D4D"
        stroke="#232323"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7"
        y="7"
        width="25"
        height="4"
        rx="1.25"
        fill="#aaa"
        className="skeleton1"
      />
      <rect
        x="7"
        y="15"
        width="25"
        height="3"
        rx="1"
        fill="#999"
        className="skeleton2"
      />
      <rect
        x="7"
        y="21"
        width="25"
        height="3"
        rx="1"
        fill="#888"
        className="skeleton3"
      />
    </svg>
  )
}
