import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { ChevronRight } from "react-feather"
import Front from "../../images/front-vertical.svg"
import Intercom from "../../images/intercom-vertical.svg"
import Zendesk from "../../images/zendesk-vertical.svg"
import Shopify from "../../images/shopify-vertical.svg"

import IntegrationsRotating from "../../components/integration-rotating"

import logo from "../../images/logo.svg"
import ChatIcon from "../../components/Icons/chat-icon"
import HelpIcon from "../../components/Icons/help-icon"
import Input from "../../components/Input/Input"

import SignupModal from "../../Features/SignupModal"

const Integrations = () => {
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [isEmailFocused1, setIsEmailFocused1] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [email1, setEmail1] = useState("")
  const [emailError1, setEmailError1] = useState("")
  return (
    <Layout>
      <SEO
        title="AI Integrations for customer support "
        description="Use our AI integrations to instantly generate responses for your customer support team and one-click actions to instantly resolve customer requests"
      />
      <SignupModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        modalUpdatedSucess={() => {
          setIsModalOpen(false)
        }}
        email={email1.length > 0 ? email1 : email}
      />
      <div className="home-container">
        <div className="hero first-hero">
          <div className="hero-left">
            {/* <div className="animated-integrations">
              <img
                src={IntegrationsRotating}
                alt="Integrations"
                draggable={false}
              />
            </div> */}
            <h1 className="big-h">AI Support Integrations</h1>
            <p className="big-p" style={{ margin: 0 }}>
              Use our AI integrations to instantly generate responses for your
              customer support team and one-click actions to instantly resolve
              customer requests
            </p>

            <button
              className="access-button dark-button"
              style={{ margin: "24px 0px 0px 0px" }}
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              Request access <ChevronRight className="access-chevron" />
            </button>
          </div>

          <div className="hero-right integrations-hero-right">
            <div className="rotating-integrations">
              <IntegrationsRotating />
            </div>
          </div>
        </div>
        <div className="clipper">
          <div className="integrations-outer">
            <h2 className="integration-h-2">
              Integrations <span className="count">4</span>
            </h2>
            <div className="our-integrations">
              <div className="integrations integrations-container">
                <Link to="/integrations/front">
                  <div className="available-integration">
                    <img src={Front} className="integration-logo" />
                    <div className="integration-info">
                      <h3 className="integration-title">Front</h3>
                      <p className="integration-description">
                        Connect Aide to Front and respond to customer requests
                        in a fraction of the time
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="not-yet available-integration">
                  <img src={Zendesk} className="integration-logo" />
                  <div className="integration-info">
                    <h3 className="integration-title">Zendesk</h3>
                    <p className="integration-description">
                      Connect Aide to Zendesk and respond to customer requests
                      in a fraction of the time
                    </p>
                  </div>
                </div>

                <div className="not-yet available-integration">
                  <img src={Intercom} className="integration-logo" />
                  <div className="integration-info">
                    <h3 className="integration-title">Intercom</h3>
                    <p className="integration-description">
                      Connect Aide to Intercom and respond to customer requests
                      in a fraction of the time
                    </p>
                  </div>
                </div>

                <div className="not-yet available-integration">
                  <img src={Shopify} className="integration-logo" />
                  <div className="integration-info">
                    <h3 className="integration-title">Shopify</h3>
                    <p className="integration-description">
                      Instantly process refunds, return labels, or modify
                      customer orders
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Integrations
