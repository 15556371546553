import React from "react"
import { navigate } from "@reach/router"

const IntegrationRotating = () => (
  <>
    <svg
      width="516"
      height="517"
      viewBox="0 0 516 517"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <circle
            cx="258"
            cy="257"
            r="219.5"
            stroke="#d7d7d7"
            strokeDasharray="7.5 7.5"
          />
          <animateTransform
            xmlns="http://www.w3.org/2000/svg"
            attributeName="transform"
            dur="160s"
            from="0 258 257"
            to="360 258 257"
            repeatCount="indefinite"
            type="rotate"
          />
        </g>
        <g>
          <circle
            cx="258"
            cy="257"
            r="199.421"
            stroke="#c4c4c4"
            strokeWidth="1"
            strokeDasharray="7.5 7.5"
          />
          <animateTransform
            xmlns="http://www.w3.org/2000/svg"
            attributeName="transform"
            dur="320s"
            from="0 258 257"
            to="-360 258 257"
            repeatCount="indefinite"
            type="rotate"
          />
        </g>
        <circle
          cx="258"
          cy="257"
          r="99.5"
          fill="#F2F2F2"
          fillOpacity="0.2"
          stroke="#F0F0F0"
        />
        <g id="bowTie">
          <g filter="url(#filter0_d)">
            <circle cx="258" cy="257" r="64" fill="white" />
            <circle
              cx="258"
              cy="257"
              r="63.4052"
              stroke="#E0E0E0"
              strokeWidth="1.18958"
            />
          </g>
          <path
            d="M294.887 257.652C294.887 263.11 293.931 266.973 293.098 269.303C292.48 271.033 290.778 271.984 288.944 271.864C286.178 271.681 281.733 271.225 276.794 270.085C271.545 268.874 268.397 266.733 266.982 265.559C266.455 265.122 266.463 264.346 266.856 263.786C267.647 262.66 268.765 260.573 268.765 257.773C268.765 255.134 267.772 253.316 266.996 252.297C266.553 251.717 266.497 250.871 267.044 250.388C268.743 248.889 272.736 245.976 279.288 244.653C283.025 243.898 286.571 243.57 288.943 243.428C290.777 243.318 292.48 244.271 293.098 246.001C293.931 248.331 294.887 252.195 294.887 257.652Z"
            fill="#373737"
            stroke="#373737"
            strokeWidth="2.01624"
          />
          <path
            d="M221.479 257.652C221.479 263.11 222.435 266.973 223.268 269.303C223.886 271.033 225.588 271.984 227.422 271.864C230.188 271.681 234.633 271.225 239.572 270.085C244.821 268.874 247.969 266.733 249.385 265.559C249.911 265.122 249.903 264.346 249.51 263.786C248.719 262.66 247.602 260.573 247.602 257.773C247.602 255.134 248.594 253.316 249.37 252.297C249.813 251.717 249.869 250.871 249.322 250.388C247.623 248.889 243.63 245.976 237.079 244.653C233.341 243.898 229.795 243.57 227.423 243.428C225.589 243.318 223.886 244.271 223.268 246.001C222.435 248.331 221.479 252.195 221.479 257.652Z"
            fill="#373737"
            stroke="#373737"
            strokeWidth="2.01624"
          />
          <ellipse
            rx="5.39983"
            ry="5.99981"
            transform="matrix(1 0 0 -1 258.285 257.987)"
            fill="#373737"
            stroke="#373737"
            strokeWidth="2.01624"
          />
        </g>
        <g>
          <g
            id="front"
            className="has-link"
            onClick={() => {
              navigate("/integrations/front")
            }}
          >
            <g>
              <animateTransform
                xmlns="http://www.w3.org/2000/svg"
                attributeName="transform"
                dur="90s"
                from="0 262.476 461"
                to="-360 262.476 461"
                repeatCount="indefinite"
                type="rotate"
              />
              <g filter="url(#filter1_d)">
                <circle cx="262.476" cy="461" r="44" fill="white" />
                <circle cx="262.476" cy="461" r="43.5" stroke="#E0E0E0" />
              </g>
              <path
                d="M248.476 447.328C248.476 443.281 251.757 440 255.804 440H279.303V450.429C279.303 452.452 277.663 454.093 275.639 454.093H266.091C264.146 454.093 262.568 455.67 262.568 457.616V480.375C262.568 482.399 260.928 484.039 258.904 484.039H248.476V447.328Z"
                fill="#001B38"
              />
              <path
                d="M256.667 457.704C256.667 462.957 260.926 467.216 266.179 467.216C271.433 467.216 275.692 462.957 275.692 457.704C275.692 452.45 271.433 448.191 266.179 448.191C260.926 448.191 256.667 452.45 256.667 457.704Z"
                fill="url(#paint0_linear)"
              />
              <path
                opacity="0.5"
                d="M256.667 457.704C256.667 462.957 260.926 467.216 266.179 467.216C271.433 467.216 275.692 462.957 275.692 457.704C275.692 452.45 271.433 448.191 266.179 448.191C260.926 448.191 256.667 452.45 256.667 457.704Z"
                fill="url(#paint1_linear)"
              />
            </g>
          </g>
          <g id="intercom">
            <animateTransform
              xmlns="http://www.w3.org/2000/svg"
              attributeName="transform"
              dur="90s"
              from="0 462 257"
              to="-360 462 257"
              repeatCount="indefinite"
              type="rotate"
            />
            <g filter="url(#filter2_d)">
              <circle cx="462" cy="257" r="44" fill="white" />
              <circle cx="462" cy="257" r="43.5" stroke="#E0E0E0" />
            </g>
            <path
              transform="translate(1 0)"
              d="M475.802 237.852H446.725C444.012 237.852 441.858 240.006 441.858 242.676V271.753C441.858 274.467 444.012 276.621 446.725 276.621H475.802C478.473 276.621 480.627 274.467 480.627 271.796V242.719C480.627 240.006 478.473 237.852 475.802 237.852ZM466.411 244.959C466.411 244.227 466.971 243.667 467.704 243.667C468.436 243.667 468.996 244.227 468.996 244.959V262.233C468.996 262.965 468.436 263.525 467.704 263.525C466.971 263.525 466.411 262.965 466.411 262.233V244.959ZM459.95 244.313C459.95 243.581 460.51 243.021 461.242 243.021C461.975 243.021 462.535 243.581 462.535 244.313V263.052C462.535 263.784 461.975 264.344 461.242 264.344C460.51 264.344 459.95 263.784 459.95 263.052V244.313ZM453.488 244.959C453.488 244.227 454.048 243.667 454.781 243.667C455.513 243.667 456.073 244.227 456.073 244.959V262.233C456.073 262.965 455.513 263.525 454.781 263.525C454.048 263.525 453.488 262.965 453.488 262.233V244.959ZM447.027 247.544C447.027 246.812 447.587 246.252 448.319 246.252C449.052 246.252 449.612 246.812 449.612 247.544V259.175C449.612 259.907 449.052 260.467 448.319 260.467C447.587 260.467 447.027 259.907 447.027 259.175V247.544ZM475.027 267.273C474.811 267.445 470.03 271.452 461.242 271.452C452.455 271.452 447.673 267.445 447.458 267.273C446.898 266.799 446.855 265.981 447.329 265.464C447.802 264.904 448.621 264.861 449.138 265.335C449.224 265.335 453.488 268.867 461.242 268.867C469.082 268.867 473.304 265.335 473.304 265.292C473.864 264.818 474.682 264.904 475.113 265.421C475.63 265.981 475.544 266.799 475.027 267.273ZM475.457 259.175C475.457 259.907 474.897 260.467 474.165 260.467C473.433 260.467 472.873 259.907 472.873 259.175V247.544C472.873 246.812 473.433 246.252 474.165 246.252C474.897 246.252 475.457 246.812 475.457 247.544V259.175Z"
              fill="#1F8DED"
            />
          </g>
          <g id="shopify">
            <animateTransform
              xmlns="http://www.w3.org/2000/svg"
              attributeName="transform"
              dur="90s"
              from="0 260.476 53"
              to="-360 260.476 53"
              repeatCount="indefinite"
              type="rotate"
            />
            <g filter="url(#filter3_d)">
              <circle cx="260.476" cy="53" r="44" fill="white" />
              <circle cx="260.476" cy="53" r="43.5" stroke="#E0E0E0" />
            </g>
            <path
              d="M274.939 39.0874C274.91 38.8753 274.724 38.7576 274.57 38.7448C274.418 38.732 271.428 38.6865 271.428 38.6865C271.428 38.6865 268.927 36.2582 268.68 36.0112C268.433 35.7642 267.951 35.8387 267.763 35.8947C267.761 35.8958 267.294 36.0403 266.507 36.2839C266.375 35.8574 266.182 35.333 265.906 34.8064C265.016 33.1075 263.712 32.2091 262.136 32.2067C262.134 32.2067 262.133 32.2067 262.131 32.2067C262.021 32.2067 261.913 32.2172 261.803 32.2266C261.757 32.1706 261.71 32.1159 261.661 32.0623C260.975 31.3282 260.095 30.9704 259.04 31.0019C257.006 31.0602 254.98 32.5295 253.337 35.1396C252.181 36.976 251.301 39.2832 251.052 41.0694C248.715 41.793 247.082 42.2987 247.046 42.3104C245.866 42.6809 245.829 42.717 245.675 43.8287C245.561 44.6687 242.476 68.5279 242.476 68.5279L268.333 73L279.54 70.2139C279.54 70.2139 274.968 39.2995 274.939 39.0874ZM265.213 36.6847C264.617 36.8688 263.94 37.0785 263.206 37.3058C263.191 36.2757 263.069 34.8425 262.589 33.6038C264.132 33.8963 264.892 35.643 265.213 36.6847ZM261.853 37.7252C260.498 38.1447 259.019 38.6026 257.536 39.0618C257.953 37.4642 258.744 35.8737 259.716 34.8308C260.077 34.4428 260.583 34.0105 261.182 33.7635C261.745 34.938 261.867 36.6008 261.853 37.7252ZM259.081 32.3559C259.559 32.3454 259.961 32.4503 260.305 32.6763C259.755 32.9618 259.223 33.372 258.725 33.9068C257.432 35.2934 256.442 37.4456 256.047 39.522C254.815 39.903 253.61 40.2771 252.501 40.6197C253.201 37.3512 255.941 32.4468 259.081 32.3559Z"
              fill="#95BF47"
            />
            <path
              d="M274.573 38.7458C274.42 38.733 271.43 38.6875 271.43 38.6875C271.43 38.6875 268.93 36.2592 268.683 36.0122C268.591 35.9201 268.466 35.8723 268.335 35.8525L268.337 72.9986L279.542 70.2137C279.542 70.2137 274.97 39.3005 274.941 39.0884C274.912 38.8762 274.725 38.7586 274.573 38.7458Z"
              fill="#5E8E3E"
            />
            <path
              d="M262.122 44.5099L260.82 49.3793C260.82 49.3793 259.368 48.7188 257.647 48.8271C255.123 48.9867 255.097 50.5784 255.122 50.9781C255.26 53.1559 260.989 53.6313 261.311 58.7325C261.564 62.7457 259.182 65.4908 255.75 65.7077C251.631 65.9675 249.364 63.538 249.364 63.538L250.237 59.8257C250.237 59.8257 252.519 61.5477 254.346 61.4324C255.539 61.3567 255.966 60.3861 255.923 59.6998C255.743 56.859 251.078 57.0267 250.783 52.3589C250.535 48.4309 253.115 44.4505 258.807 44.0916C261 43.9507 262.122 44.5099 262.122 44.5099Z"
              fill="white"
            />
          </g>
          <g id="zendesk">
            <animateTransform
              xmlns="http://www.w3.org/2000/svg"
              attributeName="transform"
              dur="90s"
              from="0 54 257"
              to="-360 54 257"
              repeatCount="indefinite"
              type="rotate"
            />
            <g filter="url(#filter4_d)">
              <circle cx="54" cy="257" r="44" fill="white" />
              <circle cx="54" cy="257" r="43.5" stroke="#E0E0E0" />
            </g>
            <path
              d="M52.5042 250.45V273.998H33L52.5042 250.45ZM52.5042 242C52.5042 244.586 51.4768 247.067 49.6479 248.896C47.819 250.725 45.3385 251.752 42.7521 251.752C40.1657 251.752 37.6852 250.725 35.8563 248.896C34.0275 247.067 33 244.586 33 242H52.5042ZM55.7174 274C55.7174 271.414 56.7448 268.933 58.5737 267.104C60.4025 265.275 62.883 264.248 65.4695 264.248C68.0559 264.248 70.5364 265.275 72.3652 267.104C74.1941 268.933 75.2216 271.414 75.2216 274H55.7174ZM55.7174 265.55V242H75.2257L55.7174 265.548V265.55Z"
              fill="#03363D"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="182.104"
              y="182.859"
              width="151.792"
              height="151.792"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="2.37917"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow"
              />
              <feOffset dy="1.75512" />
              <feGaussianBlur stdDeviation="4.75834" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d"
              x="208.476"
              y="408.475"
              width="108"
              height="108"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow"
              />
              <feOffset dy="1.47541" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d"
              x="408"
              y="204.475"
              width="108"
              height="108"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow"
              />
              <feOffset dy="1.47541" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d"
              x="206.476"
              y="0.47541"
              width="108"
              height="108"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow"
              />
              <feOffset dy="1.47541" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d"
              x="0"
              y="204.475"
              width="108"
              height="108"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow"
              />
              <feOffset dy="1.47541" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="259.48"
              y1="450.573"
              x2="273.785"
              y2="464.977"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF0057" stopOpacity="0.16" />
              <stop offset="0.861354" stopColor="#FF0057" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="259.48"
              y1="450.573"
              x2="273.785"
              y2="464.977"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF0057" stopOpacity="0.16" />
              <stop offset="0.861354" stopColor="#FF0057" />
            </linearGradient>
          </defs>
          <animateTransform
            xmlns="http://www.w3.org/2000/svg"
            attributeName="transform"
            dur="90s"
            from="0 258 258.5"
            to="360 258 258.5"
            repeatCount="indefinite"
            type="rotate"
          />
        </g>
      </g>
    </svg>
  </>
)

export default IntegrationRotating
